import React from 'react';
import { createRoot } from 'react-dom/client';
import { Autocomplete } from "./components/Autocomplete";

import 'whatwg-fetch';

export function mount(props) {
    const container = document.getElementById(props.containerId)
    const root = createRoot(container);
    return root.render(<Autocomplete {...props} />);
}